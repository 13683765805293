<template>
  <div class="item_module">
    <div>
      <div class="block">
        <el-carousel height="400px">
          <el-carousel-item v-for="url in imgList" :key="item">
            <img height="400px" :src="url"/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: ['title','url'],
  data() {
    return {
      pageData:[],
      imgList: []
    }
  },
  mounted(){
    let param = {}
    param.contentType = 7;
    param.pageNum = 1;
    param.limit = 10;
    param.pageSize = 10;
    this.axios.post('/api/v2/official/getWebContentList',param).then(res=>{
      console.log('post res image',res)
      this.pageData = []
      if(res && res.data && res.data.data && res.data.data.listCount){
        this.imgList = res.data.data.contentList[0].imageUrl
      }
    })
    if(this.pageData && this.pageData.length > 0){
    }
  },
  methods : {
  }
}
</script>

<style>
</style>
