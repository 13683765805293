import request from '@/utils/request'

// 官网内容分页列表
export function queryWebContentList(data) {
  return request({
    url: '/api/v2/official/getWebContentList',
    method: 'post',
    data: data
  })
}
