import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import Request from './utils/request.js'
 
Vue.use(ElementUI)

new Vue({
  el: '#app',
  Request,
  render: h => h(App)
})
