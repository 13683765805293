<template>
  <div class="list_module">
    <div>
      <div style="text-align: left;margin-bottom:10px;" class="list_bor_line">
        <span class="list_titlebottom">{{title}}</span> <a :href="url" target="_blank" class="fr list_more"></a>
      </div>
      <template v-for="(item,index) in pageData">
        <div class="list_container" :key="index+'_temp'">
            <span class="list_icon"><img style="margin-bottom:2px;" src="../tb_ljwct.gif"/></span>
            <span class="list_content" :title="item.newsTitle" @click="showdetail(item)">{{item.name}}</span>
            <span class="list_date">{{item.contentDate}}</span>
        </div>
      </template>
    </div>
    <el-pagination style="margin-top: 20px" background layout="prev, pager, next" @current-change="getPage" @prev-click="getPage" @next-click="getPage" :total="count"> </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: ['title','url','type','parent'],
  data() {
    return {
      pageData:[],
      count:0,
      currentPage:1,
      types:{'intro':1,'work':2,'busy':3,'news':4,'notice':5,'law':6},
    }
  },
  mounted(){    
    this.getData()
  },
  methods : {    
    getData(){
      let param = {}
      param.contentType = this.types[this.type];
      param.pageNum = this.currentPage;
      param.limit = 10;
      param.pageSize = 10;
      this.axios.post('/api/v2/official/getWebContentList',param).then(res=>{
        console.log('post res list',res)
        this.pageData = []
        if(res && res.data && res.data.data && res.data.data.listCount){
          res.data.data.contentList.forEach(item=>{
            this.pageData.push(item)
          })
          this.count = res.data.data.listCount
        }
      })
    },
    getPage(param){
      this.currentPage = param
      this.getData()
    },
    showdetail(row){
      let param = {}
      param.type = this.type
      param.title = this.title
      param.parent = this.parent
      this.$emit('detail',row,param)
    }
  }
}
</script>

<style>
.list_more{
	color:#666;
	line-height:30px;
	font-size:12px;
  margin-right: 10px;
}
.list_more:hover {
	color:#3A85FF;
}
.fr{
	float:right;
}
.list_bor_line{
	border-bottom:1px solid #3A85FF;
}
.list_titlebottom{
	display:inline-block; 
  width:90px; 
  height:30px; 
  color:#fff;  
  line-height:30px;
  text-align:center;
  background:#3A85FF;
  border-radius: 8px 8px 0 0;
}
.list_module {
  width: 100%;
  height: 190px;
  display:inline-block;
}
.list_container {
  display: flex;
  height: 30px;
  line-height: 30px;
}
.list_container .list_icon {  
  width: 25px;
}
.list_container .list_content {
  width: 100%;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}
.list_content:hover {
	color:#3A85FF;
  cursor: pointer;
}
.list_container .list_date {
  font-size: 16px;
  width: 120px;
  text-align: right;
}
</style>
