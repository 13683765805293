<template>
  <div id="app">
    <div class="header-top">
      <div class="header-item" style="margin-left: 60px">
        <i class="el-icon-message" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;"><a href="http://www.zjpilot.com">www.zjpilot.com</a></span>
      </div>
      <div class="header-item">
        <i class="el-icon-phone" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;"><a href="tel:0759-2250770">0759-2250770</a></span>
      </div>
      <div class="header-item">
        <i class="el-icon-location" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;">广东省湛江市霞山区友谊路1号海港大厦11-13楼</span>
      </div>
    </div>
    <!-- bannr图 大图 -->
    <div class="banner-section" id="banner-section" :style="{backgroundImage:'url('+bannerUrl+')',backgroundRepeat:'no-repeat'}">
      <div class="header-box" style="position:relative;z-index:99999;" >
        <div class="logo-box">
          <figure class="logo"><a href="/"><img :src="logoUrl" alt=""></a></figure>
        </div>
        <div class="join-box">
          <a href="http://zhanjiang.smartpilot.cn/" class="join-btn" target="view_window">加入业务系统</a>
        </div>
      </div>
      <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
        <div class="slide-item">
          <div class="auto-container">
            <div class="content-box">
              <h1>团结 合作 参与 敬业</h1>
              <p>Unity and Cooperation <br /> Participation and Dedication.</p>
              <div class="btn-box">
                <span @click="handleSelect('intro', '')" style="cursor: pointer;" class="theme-btn-one">了解更多</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页内容 -->
    <div id="website-content">
      <!-- 顶部菜单 -->
      <div style="margin-top:-60px;">
        <el-menu :default-active="selectIndex" @select="handleSelect" class="el-menu-demo" mode="horizontal" background-color="#3A85FF" text-color="#fff" active-text-color="#fff" active->
          <el-menu-item class="menu-item" index="index">首页</el-menu-item>
          <el-menu-item class="menu-item" index="intro">机构简介</el-menu-item>
          <el-menu-item class="menu-item" index="work">站务公开</el-menu-item>
          <el-menu-item class="menu-item" index="busy">引航业务</el-menu-item>
          <el-menu-item class="menu-item" index="news">新闻动态</el-menu-item>
          <el-menu-item class="menu-item" index="notice">通知公告</el-menu-item>
          <el-menu-item class="menu-item" index="message">留言互动</el-menu-item>
        </el-menu>
      </div>
      <div v-if="selectIndex!='index'" style="margin-top:15px;margin-bottom:15px;">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item><a href="#" @click="gotoMenu1">{{getMenu1()}}</a></el-breadcrumb-item>
          <!-- <el-breadcrumb-item v-if="selectIndex=='more'||selectIndex=='detail'"><a href="#" @click="gotoMenu2">{{getMenu2()}}</a></el-breadcrumb-item> -->
          <el-breadcrumb-item v-if="selectIndex=='detail'">{{selectDetail?selectDetail.name:''}}</el-breadcrumb-item>
          <el-breadcrumb-item></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 标题栏 -->
      <div style="display:flex;margin-top:5px;position:relative;z-index:99998;" class="layout_div">
        <div v-if="selectIndex=='index'">
          <div style="display:flex;">
            <ImagePage style="width:575px;height:400px" />
            <HomePage title="新闻动态" @more="onmore" @detail="ondetail" :pageSize="10" parent="news" type="news" style="width:500px;margin-left:25px;height:400px" />
          </div>
          <div style="display:flex;margin-top:25px;">
            <HomePage title="引航法规" @more="onmore" @detail="ondetail" :pageSize="6" parent="law" type="law" style="width:365px;" />
            <HomePage title="引航业务" @more="onmore" @detail="ondetail" :pageSize="6" parent="busy" type="busy" style="width:365px;margin-left:25px;" />
            <HomePage title="通知公告" @more="onmore" @detail="ondetail" :pageSize="6" parent="notice" type="notice" style="width:320px;margin-left:25px;" />
          </div>
        </div>
        <div v-else-if="selectIndex=='intro'">
          <div>
            <HomePage title="机构简介" @more="onmore" @detail="ondetail" :pageSize="5" parent="intro" type="intro" style="width:100%;" />
          </div>
        </div>
        <div v-else-if="selectIndex=='work'">
          <div>
            <div>
              <HomePage title="站务公开" @more="onmore" @detail="ondetail" :pageSize="5" parent="work" type="work" style="width:100%;" />
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='busy'">
          <div>
            <div>
              <div>
                <HomePage title="引航业务" @more="onmore" @detail="ondetail" :pageSize="5" parent="busy" type="busy" style="width:100%;" />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='news'">
          <div>
            <div>
              <div>
                <div>
                  <HomePage title="新闻动态" @more="onmore" @detail="ondetail" :pageSize="5" parent="news" type="news" style="width:100%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='notice'">
          <div>
            <div>
              <div>
                <div>
                  <HomePage title="通知公告" @more="onmore" @detail="ondetail" :pageSize="5" parent="notice" type="notice" style="width:100%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='message'">
          <LeaveMessagePage title="留言互动" @more="onmore" @detail="ondetail" :detail="selectDetail" parent="message" type="message" style="width:100%;" />
        </div>
        <div v-else-if="selectIndex=='more'">
          <ListPage @more="onmore" @detail="ondetail" :title="selectMoreTitle" :parent="selectMoreIndex" :type="selectMoreType" style="width:100%;" />
        </div>
        <div v-else-if="selectIndex=='detail'">
          <DetailPage title="引航动态" @more="onmore" @detail="ondetail" :detail="selectDetail" style="width:100%;" />
        </div>
        <div v-else>
          <div />
        </div>
      </div>
      <!-- <div style="margin-top:100px;margin-bottom:100px;">
        <div style="margin-top:10px;">版权所有：湛江港引航站 | 维护：合一网络科技有限公司</div>
        <div style="margin-top:10px;">地址：广东省湛江市霞山区友谊路1号海港大厦11-13楼</div>
        <div style="margin-top:10px;">邮编：524027 | 备案序号：粤ICP备14038585号-1</div>
        <div style="margin-top:10px;">电话：0759-2250770 | 传真：0759-2250770</div>
      </div> -->
    </div>
    <div class="footer-content">
      <div class="main-footer">
        <div class="main-content">
          <!-- 网站电话地址 -->
          <div class="footer-header">
            <div class="header-item">
              <div class="icon-box"><i class="el-icon-message" style="font-size: 45px;color:#3A85FF;"></i></div>
              <div class="header-text">
                <div style="height: 20px;line-height: 20px;">官方网站</div>
                <div style="height: 20px;line-height: 20px;"><a href="http://www.zjpilot.com">www.zjpilot.com</a></div>
              </div>
            </div>
            <div class="header-item" style="background: #3A85FF;">
              <div class="icon-box"><i class="el-icon-phone-outline" style="font-size: 45px;color:#fff;"></i></div>
              <div class="header-text">
                <div style="height: 20px;line-height: 20px;">联系电话</div>
                <div style="height: 20px;line-height: 20px;"><a style="color: #fff;" href="tel:0759225077">+0759-2250770</a></div>
              </div>
            </div>
            <div class="header-item">
              <div class="icon-box"><i class="el-icon-location-information" style="font-size: 45px;color:#3A85FF;"></i></div>
              <div class="header-text">
                <div style="height: 20px;line-height: 20px;">地址</div>
                <div style="height: 20px;line-height: 20px;color:#A7A7A7">广东省湛江市霞山区友谊路1号海港大厦11-13楼</div>
              </div>
            </div>
          </div>
          <!-- 网站 -->
          <div class="widget-section">
            <!-- 第一列 -->
            <div style="width: 33%;height: 100%;">
              <div class="logo-box">
                <a href="/"><img :src="logoUrl" alt=""></a>
              </div>
              <div class="text1" style="margin-top: 30px">商业合作请留下您的联系方式，我们会联系您</div>
              <div style="margin-top: 30px;">
                <div class="word-btn" target="view_window" style="cursor: pointer;" @click="handleSelect('message', '')">前往留言互动</div>
              </div>
              <div class="text1" style="margin-top: 30px">湛江引航祝您生活愉快!</div>
            </div>
            <!-- 第二列 -->
            <div style="width: 16%;height: 100%;">
              <div class="text1" style="font-size: 20px;font-weight: 700;">快捷通道</div>
              <div class="text1 link-item" style="margin-top: 30px;"><a href="/">首页</a></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="handleSelect('intro', '')">关于湛江港引航站</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('http://zhanjiang.smartpilot.cn/html/diaoduyilan')">引航计划</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="handleSelect('news', '')">新闻资讯</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="handleSelect('message', '')">网上互动</span></div>
            </div>
            <!-- 第三列 -->
            <div style="width: 16%;height: 100%;">
              <div class="text1" style="font-size: 20px;font-weight: 700;">相关网站</div>
              <div class="text1 link-item" style="margin-top: 30px;"><span style="cursor: pointer;" @click="openUrl('https://www.mot.gov.cn/')">中华人民共和国交通部</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('http://www.chinapilotage.org/index.aspx')">中国引航网</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('https://www.zhanjiang.gov.cn/')">湛江市人民政府</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('https://www.zhanjiang.gov.cn/jtysj/')">湛江市交通运输局</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('https://gd.msa.gov.cn/aspx/gdmsa/index.aspx')">广东海事局</span></div>
            </div>
            <!-- 第三列 -->
            <div style="width: 33%;height: 100%;text-align: center;">
              <div class="text1 link-item" style="margin-top: 55px;"><span style="cursor: pointer;" @click="openUrl('https://www.zjport.com/')">湛江港(集团)股份公司</span></div>
              <div class="text1 link-item" style="margin-top: 20px;"><span style="cursor: pointer;" @click="openUrl('http://zhanjiang.smartpilot.cn/')">智慧港航云服务平台</span></div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="main-content" style="display: flex;">
            <div style="width: 40%;font-size: 16px;font-weight: 700;color: #fff;"><a href="https://beian.miit.gov.cn">备案号：粤ICP备14038585号-1</a></div>
            <div style="width: 60%;font-size: 16px;font-weight: 700;color: #fff;">湛江港引航站网站 版权所有 © Copyright 2021 zjpilot.com All rights reserved 外链部</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
import DetailPage from './components/DetailPage.vue'
import ListPage from './components/ListPage.vue'
import LeaveMessagePage from './components/LeaveMessagePage.vue'
import ImagePage from './components/ImagePage.vue'
import { queryWebContentList } from './api/website'

export default {
  name: 'App',
  components: {
    HomePage, ImagePage, DetailPage, ListPage, LeaveMessagePage
  },
  data () {
    return {
      selectIndex: 'index',
      selectMoreIndex: '',
      imgIndex: 0,
      timer: '',
      bannerUrl: '',
      images: [
        require('./assets/images/banner/banner-1.png'),
        require('./assets/images/banner/banner-2.png')
      ],
      
      types:[
        {
          name:'机构简介',
          index: 'intro',
          id:'1'
        },{
          name:'站务公开',
          index: 'work',
          id:'2'
        },{
          name:'引航业务',
          index: 'busy',
          id:'3'
        },{
          name:'新闻动态',
          index: 'news',
          id:'4'
        },{
          name:'通知公告',
          index: 'notice',
          id:'5'
        }
      ],
      allList: [],
      logoUrl: require('./assets/images/logo.png'),
      footerBgUrl: require('./assets/images/background/forum-title.png'),
      selectMoreType: '',
      selectMoreTitle: '',
      selectMoreTitle: '',
      selectDetail: null,
      detailParam: null,
    }
  },
  created () {
    document.title = '湛江引航站官网'
    this.changeIndex()
    this.timer = setInterval(this.changeIndex, 10000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  methods: {
    openUrl(url){
      window.open(url)
    },
    queryWebContent(type){
      let query = {}
      query.type = type
      query.pageSize = 10
      query.pageNum = 1
      queryWebContentList(query).then((res) => {
        this.tableData = res.data.records
      })
    },
    changeIndex () {
      if (this.imgIndex < this.images.length - 1) {
        this.imgIndex++
      } else {
        this.imgIndex = 0
      }
      this.bannerUrl = this.images[this.imgIndex]
    },
    handleSelect (key, keyPath) {
      console.log('handleSelect', key)
      this.selectMoreType = ''
      this.selectMoreTitle = ''
      this.selectMoreIndex = ''
      this.selectIndex = key
      this.$forceUpdate()
    },
    gotoMenu1 () {
      if (this.selectIndex == 'more' || this.selectIndex == 'detail') {
        if (this.selectMoreIndex == 'intro') {
          this.selectIndex = 'intro'
        } else if (this.selectMoreIndex == 'work') {
          this.selectIndex = 'work'
        } else if (this.selectMoreIndex == 'busy') {
          this.selectIndex = 'busy'
        } else if (this.selectMoreIndex == 'news') {
          this.selectIndex = 'news'
        } else if (this.selectMoreIndex == 'notice') {
          this.selectIndex = 'notice'
        } else if (this.selectMoreIndex == 'message') {
          this.selectIndex = 'message'
        }
        this.selectMoreType = ''
        this.selectMoreTitle = ''
      }
    },
    gotoMenu2 () {
      console.log('menu2', this.detailParam)
      this.selectMoreTitle = this.detailParam.title
      this.selectMoreType = this.detailParam.type
      this.selectMoreIndex = this.detailParam.parent
      this.selectIndex = 'more'
    },
    onmore (param) {
      console.log('onmore', param)
      this.selectMoreTitle = param.title
      this.selectMoreType = param.type
      this.selectMoreIndex = param.parent
      this.selectIndex = 'more'
      this.detailParam = param
    },
    ondetail (data, param) {
      console.log('ondetail', data, param)
      this.selectIndex = 'detail'
      this.selectMoreType = param.type
      this.selectMoreIndex = param.parent
      this.selectDetail = data
      this.detailParam = param
    },
    getMenu1 () {
      let menu1 = ''
      if (this.selectIndex == 'index') {
        menu1 = '首页'
      } else if (this.selectIndex == 'intro') {
        menu1 = '机构简介'
      } else if (this.selectIndex == 'work') {
        menu1 = '站务公开'
      } else if (this.selectIndex == 'busy') {
        menu1 = '引航业务'
      } else if (this.selectIndex == 'news') {
        menu1 = '新闻动态'
      } else if (this.selectIndex == 'message') {
        menu1 = '留言互动'
      } else if (this.selectIndex == 'notice') {
        menu1 = '通知公告'
      } else if (this.selectIndex == 'more' || this.selectIndex == 'detail') {
        if (this.selectMoreIndex == 'intro') {
          menu1 = '机构简介'
        }if (this.selectMoreIndex == 'law') {
          menu1 = '引航法规'
        } else if (this.selectMoreIndex == 'work') {
          menu1 = '站务公开'
        } else if (this.selectMoreIndex == 'busy') {
          menu1 = '引航业务'
        } else if (this.selectMoreIndex == 'news') {
          menu1 = '新闻动态'
        } else if (this.selectMoreIndex == 'message') {
          menu1 = '留言互动'
        } else if (this.selectMoreIndex == 'notice') {
          menu1 = '通知公告'
        }
      }
      return menu1
    },
    getMenu2 () {
      let menu2 = ''
      if (this.selectMoreType == 'law') {
        menu2 = '引航法规'
      } else if (this.selectMoreType == 'flow') {
        menu2 = '业务流程'
      } else if (this.selectMoreType == 'hr') {
        menu2 = '人事招聘'
      } else if (this.selectMoreType == 'news') {
        menu2 = '新闻动态'
      } else if (this.selectMoreType == 'study') {
        menu2 = '能力建设'
      } else if (this.selectMoreType == 'intro') {
        menu2 = '机构简介'
      } else if (this.selectMoreType == 'leader') {
        menu2 = '领导成员'
      } else if (this.selectMoreType == 'apply') {
        menu2 = '申请手续'
      } else if (this.selectMoreType == 'rate') {
        menu2 = '费率计算'
      } else if (this.selectMoreType == 'pilot') {
        menu2 = '引航动态'
      } else if (this.selectMoreType == 'notice') {
        menu2 = '通知公告'
      }
      return menu2
    }
  }
}
</script>

<style scoped>
@import '~@/assets/style.scss';
#app {
  width: 100%;
  font-family: Helvetica, sans-serif;
  display: block;
  margin: 0 auto;
}
#website-content {
  width: 1100px;
  font-family: Helvetica, sans-serif;
  display: block;
  margin: 0 auto;
}
.el-menu-demo .is-active{
  font-weight: 700 !important;
  color: #3A85FF !important;
  background: #FFF !important;
}
.menu-item{
  width: 12%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
body {
  margin: 0;
}
.layout_div div {
  width: 100%;
}
</style>
