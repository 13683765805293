<template>
  <div class="item_detail">
    <div class="message-content">
      <div style="width: 30%;text-align: left;">
        <div style="color: #3A85FF;font-size: 20px;font-weight: 600;">“坦诚交流，竭诚服务”</div>
        <div style="font-size: 36px;font-weight: 600;margin-top: 20px;">留言互动</div>
        <div style="font-size: 18px;font-weight: 600;margin-top: 20px;">湛江港引航站，将一如既往克服困难，全力以赴做好优质的引航保障服务。</div>
      </div>
      <div style="width: 70%;margin-left: 10px;">
        <div style="display: flex;justify-content: space-between;">
          <el-input placeholder="公司名称(必填)" v-model="form.companyName" clearable style="width: 47%;"> </el-input>
          <el-input placeholder="联系人(必填)" v-model="form.userName" clearable style="width: 47%;"> </el-input>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 10px;">
          <el-input placeholder="联系电话(必填)" v-model="form.userPhone" clearable style="width: 47%;"> </el-input>
          <el-input placeholder="联系传真" v-model="form.userFax" clearable style="width: 47%;"> </el-input>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 10px;">
          <el-input placeholder="E-mail" v-model="form.userEmail" clearable style="width: 47%;"> </el-input>
          <el-input placeholder="反馈主题(必填)" v-model="form.messageTitle" clearable style="width: 47%;"> </el-input>
        </div>
        <div style="width: 100%;margin-top: 10px;">
          <el-input type="textarea" placeholder="反馈内容(必填)" :rows="5" v-model="form.messageInfo" clearable></el-input>
        </div>
        <div style="margin-top: 10px">
          <el-button style="background: #3A85FF;color: #fff" @click="submitMessage">提交留言</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailPage',
  props: ['title','url','detail'],
  data() {
    return {
      form:{
        companyName: '',
        userName: '',
        userPhone: '',
        userFax: '',
        userEmail: '',
        messageTitle: '',
        messageInfo: '',
      },
      pageData:[]
    }
  },
  mounted(){
  },
  methods : {
    submitMessage(){
      if(!this.form.companyName){
        this.$message({
          showClose: true,
          message: '请填写公司名称',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.userName){
        this.$message({
          showClose: true,
          message: '请填写联系人',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.userPhone){
        this.$message({
          showClose: true,
          message: '请填写联系电话',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.messageTitle){
        this.$message({
          showClose: true,
          message: '请填写反馈主题',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.messageInfo){
        this.$message({
          showClose: true,
          message: '请填写反馈内容',
          type: 'warning'
        });
        return false;
      }
      this.axios.put('/api/v2/official/addMessageInfo',this.form).then(res=>{
        this.$message({
          showClose: true,
          message: '提交成功',
          type: 'success'
        });
        this.form = {}
      })
    },
    getData(param){
      getData(param)
    },

  }
}
</script>

<style scoped>
.message-content{
  width: 100%;
  display: flex;
  margin-top: 50px;
}
.message-content .el-input__inner{
  width: 370px !important;
  height: 70px !important;
}
.detail_title{
  font-size: 30px;
  text-align: center;
  margin: 30px 0 30px 0;
}
.detail_date {
  overflow: hidden;
  color: #666666;
  font-size: 14px;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
  padding-top: 20px;
}
.detail_content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.item_detail {
  width: 100%;
  height: 100%;
  padding: 20px 0px;
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
  border: 0px;
}
</style>
